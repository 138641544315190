import { ArrayPath, FieldArray, FieldValues, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { Grid } from "@mui/material";
import { FormListControlProps } from "./types";
import { exportAsCsv, exportAsJson } from "utils-ts/functions";
import { useMessages } from "utils-ts/hooks";
import { List } from "components-ts/controls";
import FormArrayHelperText from "components-ts/controls/inputs/FormArrayHelperText";
import { Spacing, View } from "components-ts/view";
import { FormPrefixProvider } from "../contexts";
import { usePrefixContext } from "../contexts";
import { GridHeader } from "../grid";
import { FormColumn } from "../layout";

const FormList = <T extends {}, TFieldValues extends FieldValues = FieldValues, TName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>>({
    name,
    canAddItem = true,
    canRemoveItem,
    defaultValue,
    children,
    label,
    viewType,
    iconName,
    hideDivider = false,
    useCollapse = false,
    isCollapsed = false,
    type,
    searchBy,
    searchLabel,
    canCopyItem = false,
    canExportItem = false,
    exportPartItem,
    exportPartLabel,
    canExportAllItems,
    exportAllItemsAs,
    transformItems,
    exportAllItemsFileName,
    exportAllItemsButtonText,
    canImportItem,
    importItemButtonText,
    handleOnPaste = undefined,
    isSecondaryGrid,
    noSpacing = false,
}: FormListControlProps<T, TFieldValues, TName>): JSX.Element => {
    const prefix = usePrefixContext();
    const key = prefix ? `${prefix}.${name}` : name;
    const { showSuccessMessage, showErrorMessage } = useMessages();
    const { fields, append, remove, move } = useFieldArray<TFieldValues, TName>({
        name: key as TName,
    });
    const { unregister, getValues, setValue } = useFormContext();
    const val = getValues(key as TName);
    const exportPartItemProps =
        exportPartItem && exportPartLabel
            ? {
                  exportPartItem: (index: number) => exportPartItem(val[index] as T, index),
                  exportPartLabel: exportPartLabel,
              }
            : {
                  exportPartItem: undefined,
                  exportPartLabel: undefined,
              };

    const exportAllPartProps =
        canExportAllItems !== undefined
            ? {
                  exportAllItems: () => {
                      if (exportAllItemsAs === "json") {
                          exportAsJson(transformItems ? transformItems(val) : val, exportAllItemsFileName ? exportAllItemsFileName : name);
                      } else if (exportAllItemsAs == "csv") {
                          exportAsCsv(transformItems(val), exportAllItemsFileName ? exportAllItemsFileName : name);
                      }
                  },
                  exportAllItemsButtonText,
              }
            : {
                  exportAllItems: undefined,
                  exportAllItemsButtonText: undefined,
              };

    const importItemPartProps =
        canImportItem !== undefined
            ? {
                  importItem: (itemAsJson: string) => {
                      const json = JSON.parse(itemAsJson);
                      append(json);
                  },
                  importItemButtonText,
              }
            : {
                  importItem: undefined,
                  importItemButtonText: undefined,
              };

    const listTypeProps =
        type === "draggable"
            ? {
                  type,
                  moveItem: (fromIndex: number, toIndex: number) => {
                      move(fromIndex, toIndex);
                  },
                  searchItem: undefined,
                  searchLabel: undefined,
                  hideDivider: undefined,
              }
            : {
                  type,
                  moveItem: undefined,
                  searchItem: (search: string) => {
                      if (searchBy) {
                          return (val as Array<T>).findIndex((v) => v[searchBy] === search);
                      }

                      return -1;
                  },
                  searchLabel,
                  hideDivider,
              };

    const list = (
        <div
            onPaste={(event) => {
                if (handleOnPaste && !(String(event.target) == "[object HTMLInputElement]")) {
                    event.preventDefault();
                    const paste = (event.clipboardData || window.Clipboard).getData("text");
                    const { rows, error } = handleOnPaste(paste);
                    if (rows.length > 0) {
                        rows.forEach((row) => {
                            append(row as FieldArray<TFieldValues, TName>);
                        });
                        showSuccessMessage(`Pomyślnie wklejono ${rows.length} wierszy`);
                    }

                    if (error.length > 0) {
                        showErrorMessage(`Wklejane wiersze zawierają błędy:\n${error.trimEnd()}`);
                    }
                }
            }}
        >
            <FormArrayHelperText name={key} />
            <List
                addItem={canAddItem ? () => append({ index: (val || []).length, ...defaultValue } as FieldArray<TFieldValues, TName>) : undefined}
                removeItem={(index) => {
                    if (index < fields.length - 1) {
                        for (let i = index; i < fields.length - 1; i++) {
                            const nextRow = getValues(`${key}.${i + 1}`);
                            setValue(`${key}.${i}`, nextRow);
                        }

                        unregister(`${key}.${fields.length - 1}`);
                        remove(fields.length - 1);
                    } else {
                        unregister(`${key}.${index}`);
                        remove(index);
                    }
                }}
                canRemoveItem={canRemoveItem}
                copyItem={canCopyItem ? (index) => append({ index: fields.length, ...val[index] }) : undefined}
                exportItem={
                    canExportItem
                        ? (index) => {
                              exportAsJson(val[index], `${name}_${index}`);
                          }
                        : undefined
                }
                {...listTypeProps}
                {...exportPartItemProps}
                {...exportAllPartProps}
                {...importItemPartProps}
            >
                {fields.map((item, index) => {
                    return (
                        <FormPrefixProvider
                            name={`${name}.${index}`}
                            key={item.id}
                        >
                            <FormColumn>{children({ item: item as T, index, key: item.id })}</FormColumn>
                        </FormPrefixProvider>
                    );
                })}
            </List>
        </div>
    );

    if (label) {
        if (viewType === "GridContainer") {
            return (
                <Grid
                    item
                    xs={12}
                    style={{ width: "100%" }}
                >
                    <Spacing spacing={noSpacing ? 0 : 1}>
                        <GridHeader
                            title={label}
                            iconName={iconName}
                            isSecondaryGrid={isSecondaryGrid}
                        />
                        {list}
                    </Spacing>
                </Grid>
            );
        } else {
            return (
                <Grid
                    item
                    xs={12}
                    style={{ width: "100%" }}
                >
                    <View
                        headerText={label}
                        useCollapse={useCollapse}
                        isCollapsed={isCollapsed}
                    >
                        <Spacing spacing={noSpacing ? 0 : 1}>{list}</Spacing>
                    </View>
                </Grid>
            );
        }
    } else {
        return (
            <Grid
                item
                xs={12}
                style={{ width: "100%" }}
            >
                {list}
            </Grid>
        );
    }
};

export default FormList;
