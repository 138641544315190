import { DeliveryPredicate } from "Commerce-Shared";
import { ExtendedValidator } from "../extendedValidator";
import { DateLeadPredicateValidator } from "./dateLeadPredicateValidator";
import { DatePredicateValidator } from "./datePredicateValidator";
import { PostCodePredicateValidator } from "./postCodePredicateValidator";

const datePredicateValidator =
    new DatePredicateValidator();
const dateLeadPredicateValidator =
    new DateLeadPredicateValidator();

const postCodePredicateValidator =
    new PostCodePredicateValidator();

export class DeliveryPredicateValidator extends ExtendedValidator<DeliveryPredicate> {
    constructor(required?: boolean) {
        super();

        if (required) {
            this.atLeastOneOfFieldNotEmpty([
                "deliveryMethods",
                "postcodes",
                "currentDates",
                "deliveryDates",
                "closingDates",
                "isWholeDayDelivery",
                "isSameDayDelivery",
                "isExpressDelivery",
                "isBeforeHighHoliday",
                "isNonTradeSunday",
                "isMondayAfterNonTradeSunday",
                "isMorningAfterHoliday",
            ]);
        }

        this.ruleForEach("postcodes")
            .notNull()
            .setValidator(
                postCodePredicateValidator
            )
            .whenNotEmpty(
                "postcodes",
                "AppliesToAllValidators"
            );

        this.ruleFor("currentDates")
            .must(
                (o) =>
                    o === undefined ||
                    o === null ||
                    o.every(
                        (p) =>
                            p.isOnlyNextDay ===
                            undefined
                    )
            )
            .whenNotEmpty();

        this.ruleForEach("currentDates")
            .notNull()
            .setValidator(datePredicateValidator)
            .whenNotEmpty(
                "currentDates",
                "AppliesToAllValidators"
            );

        this.ruleForEach("deliveryDates")
            .notNull()
            .setValidator(datePredicateValidator);

        this.ruleFor("closingDates")
            .notNull()
            .whenNotEmpty();

        this.ruleForEach("closingDates")
            .notNull()
            .setValidator(
                dateLeadPredicateValidator
            );
    }
}
