import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { ToggleButtonGroup } from "components-ts/controls";
import { FormPrefixProvider, usePrefixContext } from "components-ts/forms/contexts";
import { FormRow } from "components-ts/forms/layout";
import FormTextField from "../FormTextField";

const PostcodePredicate: React.FC<{
    readOnly?: boolean;
}> = ({ readOnly }) => {
    const form = useFormContext();
    const prefix = usePrefixContext();
    const { t } = useTranslation();
    const mask = form.getValues(`${prefix !== undefined ? `${prefix}.` : ""}mask`);
    const [type, setType] = useState<"mask" | "range">(mask !== undefined ? "mask" : "range");

    return (
        <>
            <ToggleButtonGroup
                value={type}
                items={[
                    {
                        value: "mask",
                        name: "Maska",
                    },
                    {
                        value: "range",
                        name: "Zakres",
                    },
                ]}
                onChange={(newValue) => {
                    if (newValue === "mask") {
                        form.setValue(`${prefix !== undefined ? `${prefix}.` : ""}range`, undefined);
                        setType(newValue);
                    } else if (newValue === "range") {
                        form.setValue(`${prefix !== undefined ? `${prefix}.` : ""}mask`, undefined);
                        setType(newValue);
                    }
                }}
                readOnly={readOnly}
                label={t(common.type)}
            />

            {type === "range" ? (
                <FormRow>
                    <FormPrefixProvider name="range">
                        <FormTextField
                            name="postcodeFrom"
                            label={t(common.rangeFrom)}
                            readOnly={readOnly}
                        />

                        <FormTextField
                            name="postcodeTo"
                            label={t(common.rangeTo)}
                            readOnly={readOnly}
                        />
                    </FormPrefixProvider>
                </FormRow>
            ) : (
                <FormRow>
                    <FormPrefixProvider name="mask">
                        <FormTextField
                            name="mask"
                            label={t(common.mask)}
                            readOnly={readOnly}
                        />
                    </FormPrefixProvider>
                </FormRow>
            )}
        </>
    );
};

export default PostcodePredicate;
