import { useEffect, useState } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { Collapse } from "@mui/material";
import { api } from "api";
import _ from "lodash";
import { common } from "translations";
import { Merchants } from "types/sharedEnums";
import { useTranslation } from "utils-ts/hooks";
import { FormPrefixProvider } from "components-ts/forms/contexts/FormPrefixContext";
import { GridHeader } from "components-ts/forms/grid";
import { FormColumn } from "components-ts/forms/layout";
import FormAutocomplete from "../FormAutocomplete";
import FormProductIdsList from "../FormProductIdsList";
import FormSelect from "../FormSelect";

const ProductPredicate: React.FC<{ name: string; label: string; readOnly?: boolean }> = ({ name, label, readOnly }) => {
    const { t } = useTranslation();
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const { data: categories = [], status: categoriesStatus } = api.commerce.autocomplete.useFindContainerCategories();
    const { data: brands = [], status: brandsStatus } = api.commerce.autocomplete.useFindBrands();
    const { data: producers = [], status: producersStatus } = api.commerce.autocomplete.useFindProducers();
    const { data: suppliers = [], status: suppliersStatus } = api.commerce.autocomplete.useFindSuppliers();
    const { data: tags = [], status: tagsStatus } = api.commerce.autocomplete.useFindTags();
    const {
        formState: { errors },
    } = useFormContext();
    const fieldError = _.get(errors, name) as FieldError;
    const hasError = Boolean(fieldError);

    return (
        <>
            <GridHeader
                title={label}
                handleCollapse={(isCollapsed) => setCollapsed(isCollapsed)}
                isCollapsed={collapsed}
            />

            <Collapse in={collapsed || hasError}>
                <FormPrefixProvider name={name}>
                    <FormColumn>
                        <FormProductIdsList
                            name="products"
                            label={t(common.products)}
                            readOnly={readOnly}
                            width="fullWidth"
                        />

                        <FormAutocomplete
                            name="categories"
                            label={t(common.categories)}
                            items={categories}
                            readOnly={readOnly}
                            isLoadingItems={categoriesStatus === "pending"}
                            width="fullWidth"
                            multiple
                            disableEmpty
                        />

                        <FormAutocomplete
                            name="brands"
                            label={t(common.brands)}
                            items={brands}
                            readOnly={readOnly}
                            isLoadingItems={brandsStatus === "pending"}
                            width="fullWidth"
                            multiple
                            disableEmpty
                        />

                        <FormAutocomplete
                            name="producers"
                            label={t(common.producers)}
                            items={producers}
                            readOnly={readOnly}
                            isLoadingItems={producersStatus === "pending"}
                            width="fullWidth"
                            multiple
                            disableEmpty
                        />

                        <FormAutocomplete
                            name="suppliers"
                            label={t(common.suppliers)}
                            items={suppliers}
                            readOnly={readOnly}
                            isLoadingItems={suppliersStatus === "pending"}
                            width="fullWidth"
                            multiple
                            disableEmpty
                        />

                        <FormAutocomplete
                            name="tags"
                            label={t(common.tags)}
                            items={tags}
                            readOnly={readOnly}
                            isLoadingItems={tagsStatus === "pending"}
                            width="fullWidth"
                            multiple
                            disableEmpty
                        />

                        <FormSelect
                            name="merchants"
                            label={t(common.merchants)}
                            items={Merchants}
                            readOnly={readOnly}
                            width="fullWidth"
                            multiple
                            disableEmpty
                        />
                    </FormColumn>
                </FormPrefixProvider>
            </Collapse>
        </>
    );
};

export default ProductPredicate;
