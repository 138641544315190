import { useGetCacheQuery } from "api/hooks";

const url = "api/v1/autocomplete/";

const useFindCampaigns = () => {
    return useGetCacheQuery<string[]>({
        app: "commerce",
        url: url + "shared/campaigns",
    });
};

const useFindContainerCategories = () => {
    return useGetCacheQuery<string[]>({
        app: "commerce",
        url: url + "content/containers/categories",
    });
};

const useFindProductSupplierNames = () => {
    return useGetCacheQuery<Record<string, string>>({
        app: "commerce",
        url: url + "offer/products/suppliers/names",
    });
};

const useFindBrands = () => {
    return useGetCacheQuery<string[]>({
        app: "commerce",
        url: url + "offer/products/brands",
    });
};

const useFindProducers = () => {
    return useGetCacheQuery<string[]>({
        app: "commerce",
        url: url + "offer/products/producers",
    });
};

const useFindSuppliers = () => {
    return useGetCacheQuery<string[]>({
        app: "commerce",
        url: url + "offer/products/suppliers",
    });
};

const useFindTags = () => {
    return useGetCacheQuery<string[]>({
        app: "commerce",
        url: url + "offer/products/tags",
    });
};

export default {
    useFindCampaigns,
    useFindContainerCategories,
    useFindProductSupplierNames,
    useFindBrands,
    useFindProducers,
    useFindSuppliers,
    useFindTags,
};
