import {
    DiscountForm,
    DiscountSearch,
    PromotionSearch,
    PurchaseScheduleForm,
    PurchaseSchedulesList,
    StockReservationForm,
    StockReservationsList,
} from "views-ts/offers";
import {
    ContentForm,
    ContentList,
    ContractsForm,
    ContractsList,
    FeedForm,
    FeedList,
    GiftChoiceForm,
    GiftChoicesList,
    GiftForm,
    GiftList,
    ProductPreview,
    ProductPreviewSearch,
    PromotionsForm,
    RewardsForm,
    RewardsList,
} from "views/offers";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { contractsRoles, feedRoles, giftsRoles, offersEditRoles, purchaseScheduleRoles, readOfferProductRoles, readOfferRoles } from "./OffersRoles";

export const Offers: PrivateRoute[] = [
    new PrivateRoute("/offers", readOfferProductRoles, undefined, "offers", undefined, [
        new PrivateRoute(Paths.Offers.ProductPreviewSearch, readOfferProductRoles, ProductPreviewSearch, "productPreview", "local_offer"),
        new PrivateRoute(Paths.Offers.ProductPreview, readOfferProductRoles, ProductPreview),

        new PrivateRoute(Paths.Offers.FeedsList, feedRoles, FeedList, "feed", "rss_feed"),
        new PrivateRoute(Paths.Offers.FeedForm, feedRoles, FeedForm),

        new PrivateRoute(Paths.Offers.ContentsList, offersEditRoles, ContentList, "productContent", "toc"),
        new PrivateRoute(Paths.Offers.ContentForm, offersEditRoles, ContentForm),

        new PrivateRoute(Paths.Offers.DiscountsList, offersEditRoles, DiscountSearch, "discounts", "money_off"),
        new PrivateRoute(Paths.Offers.DiscountForm, offersEditRoles, DiscountForm),

        new PrivateRoute(Paths.Offers.PromotionsList, readOfferRoles, PromotionSearch, "promotions", "local_atm"),
        new PrivateRoute(Paths.Offers.PromotionForm, readOfferRoles, PromotionsForm),

        new PrivateRoute(Paths.Offers.GiftsList, giftsRoles, GiftList, "gifts", "local_atm"),
        new PrivateRoute(Paths.Offers.GiftForm, giftsRoles, GiftForm),
        new PrivateRoute(Paths.Offers.GiftChoicesList, giftsRoles, GiftChoicesList),
        new PrivateRoute(Paths.Offers.GiftChoiceForm, giftsRoles, GiftChoiceForm),

        new PrivateRoute(Paths.Offers.ContractsList, contractsRoles, ContractsList, "contracts", "assignment"),
        new PrivateRoute(Paths.Offers.ContractForm, contractsRoles, ContractsForm),

        new PrivateRoute(Paths.Offers.RewardsList, giftsRoles, RewardsList, "rewards", "redeem"),
        new PrivateRoute(Paths.Offers.RewardForm, giftsRoles, RewardsForm),

        new PrivateRoute(Paths.Offers.PurchaseSchedulesList, purchaseScheduleRoles, PurchaseSchedulesList, "purchaseSchedules", "punch_clock"),
        new PrivateRoute(Paths.Offers.PurchaseScheduleForm, purchaseScheduleRoles, PurchaseScheduleForm),

        new PrivateRoute(Paths.Offers.StockReservationsList, ["AXUser"], StockReservationsList, "stockReservation", "inventory"),
        new PrivateRoute(Paths.Offers.StockReservationForm, ["AXUser"], StockReservationForm),
    ]),
];
