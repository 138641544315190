import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { DeliveryMethod, DeliveryMethods, ObsoleteDeliveryMethods } from "types/sharedEnums";
import { usePrefixContext } from "components-ts/forms/contexts";
import FormSelect from "../FormSelect";

type FormDeliveryMethodSelectProps = {
    label: string;
    /**
     * default 'deliveryMethods'
     */
    name?: string;
    readOnly?: boolean;
    multiple?: true;
    disableEmpty?: boolean;
};

const FormDeliveryMethodSelect: React.FC<FormDeliveryMethodSelectProps> = ({ label, name = "deliveryMethods", multiple, disableEmpty, readOnly }) => {
    const form = useFormContext();
    const prefix = usePrefixContext();
    const value = form.getValues(prefix !== undefined ? `${prefix}.${name}` : name);
    const [selectedObsoleteDeliveryMethods, setSelectedObsoleteDeliveryMethods] = useState<DeliveryMethod[]>([]);
    useEffect(() => {
        if (Array.isArray(value) && value.some((v) => ObsoleteDeliveryMethods.includes(v as DeliveryMethod))) {
            setSelectedObsoleteDeliveryMethods(value.filter((v) => ObsoleteDeliveryMethods.includes(v as DeliveryMethod)));
        }
    }, [value]);

    return (
        <FormSelect
            name={name}
            label={label}
            items={DeliveryMethods.filter((d) => readOnly || !ObsoleteDeliveryMethods.includes(d) || selectedObsoleteDeliveryMethods.includes(d))}
            disableEmpty={disableEmpty}
            readOnly={readOnly}
            multiple={multiple}
        />
    );
};

export default FormDeliveryMethodSelect;
