import { ProductPredicate } from "Commerce-Offer";
import { ExtendedValidator } from "../extendedValidator";

export class ProductPredicateValidator extends ExtendedValidator<ProductPredicate> {
    constructor(required: boolean) {
        super();

        if (required) {
            this.atLeastOneOfFieldNotEmpty([
                "products",
                "categories",
                "brands",
                "producers",
                "suppliers",
                "tags",
                "merchants",
            ]);
        }
    }
}
