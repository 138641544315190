import type { DateLeadPredicate as DateLeadPredicateType, DatePredicate as DatePredicateType } from "Commerce-Shared";
import { Grid } from "@mui/material";
import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { FormRow } from "components-ts/forms/layout";
import FormList from "../FormList";
import FormToggleButton from "../FormToggleButton";
import FormDeliveryMethodSelect from "../common/FormDeliveryMethodSelect";
import DateLeadPredicate from "./DateLeadPredicate";
import DatePredicate from "./DatePredicate";
import PostcodePredicate from "./PostcodePredicate";

const DeliveryPredicate: React.FC<{ readOnly?: boolean }> = ({ readOnly }) => {
    const { t } = useTranslation();

    return (
        <>
            <FormRow>
                <FormDeliveryMethodSelect
                    label={t(common.deliveryMethods)}
                    readOnly={readOnly}
                    multiple
                    disableEmpty
                />
            </FormRow>

            <FormRow>
                <FormToggleButton<boolean>
                    label={t(common.isSameDayDelivery)}
                    name="isSameDayDelivery"
                    items={[
                        { value: true, name: "Tak" },
                        { value: false, name: "Nie" },
                        { value: undefined, name: "Nie dotyczy" },
                    ]}
                    readOnly={readOnly}
                />

                <FormToggleButton<boolean>
                    label={t(common.isNonTradeSunday)}
                    name="isNonTradeSunday"
                    items={[
                        { value: true, name: "Tak" },
                        { value: false, name: "Nie" },
                        { value: undefined, name: "Nie dotyczy" },
                    ]}
                    readOnly={readOnly}
                />

                <FormToggleButton<boolean>
                    label={t(common.isMondayAfterNonTradeSunday)}
                    name="isMondayAfterNonTradeSunday"
                    items={[
                        { value: true, name: "Tak" },
                        { value: false, name: "Nie" },
                        { value: undefined, name: "Nie dotyczy" },
                    ]}
                    readOnly={readOnly}
                />

                <FormToggleButton<boolean>
                    label={t(common.isMorningAfterHoliday)}
                    name="isMorningAfterHoliday"
                    items={[
                        { value: true, name: "Tak" },
                        { value: false, name: "Nie" },
                        { value: undefined, name: "Nie dotyczy" },
                    ]}
                    readOnly={readOnly}
                />
            </FormRow>

            <FormRow>
                <Grid
                    item
                    xs={6}
                >
                    <FormList<DatePredicateType>
                        name="currentDates"
                        defaultValue={{ isNew: true }}
                        label={common.currentDates}
                        viewType="GridContainer"
                        canAddItem={!readOnly}
                        canRemoveItem={!readOnly}
                        noSpacing
                    >
                        {() => <DatePredicate readOnly={readOnly} />}
                    </FormList>
                </Grid>

                <Grid
                    item
                    xs={6}
                >
                    <FormList<DatePredicateType>
                        name="deliveryDates"
                        defaultValue={{ isNew: true }}
                        label={common.deliveryDates}
                        viewType="GridContainer"
                        canAddItem={!readOnly}
                        canRemoveItem={!readOnly}
                        noSpacing
                    >
                        {() => <DatePredicate readOnly={readOnly} />}
                    </FormList>
                </Grid>
            </FormRow>

            <FormRow>
                <Grid
                    item
                    xs={6}
                >
                    <FormList<DateLeadPredicateType>
                        name="closingDates"
                        defaultValue={{ date: { isNew: true } }}
                        label={common.closingDates}
                        viewType="GridContainer"
                        canAddItem={!readOnly}
                        canRemoveItem={!readOnly}
                        noSpacing
                    >
                        {() => <DateLeadPredicate readOnly={readOnly} />}
                    </FormList>
                </Grid>

                <Grid
                    item
                    xs={6}
                >
                    <FormList
                        name="postcodes"
                        defaultValue={{}}
                        label={common.postcodes}
                        viewType="GridContainer"
                        canAddItem={!readOnly}
                        canRemoveItem={!readOnly}
                        noSpacing
                    >
                        {() => <PostcodePredicate readOnly={readOnly} />}
                    </FormList>
                </Grid>
            </FormRow>
        </>
    );
};

export default DeliveryPredicate;
