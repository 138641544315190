import { StockReservationDocument } from "Commerce-Offer";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { api } from "api";
import { common } from "translations";
import { useForm, useTranslation, useValidator } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import {
    DeliveryPredicate,
    Form,
    FormDateTime,
    FormDivisionSelect,
    FormList,
    FormRow,
    FormSwitch,
    FormTextField,
    FormTimeSpan,
    FormWarehouseSelect,
    ProductPredicate,
} from "components-ts/forms";
import { StockReservationDocumentValidator } from "./validators";

const StockReservationForm: React.FC = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const { data, status } = api.commerce.offers.stockReservations.useGetStockReservation(id);
    const validatorResolver = useValidator(new StockReservationDocumentValidator());
    const form = useForm<StockReservationDocument>({
        resolver: validatorResolver,
        values: data || { isActive: true },
    });

    return (
        <Form
            form={form}
            initQueryStatus={status}
            submitMutation={api.commerce.offers.stockReservations.useCreateOrUpdateStockReservation(id)}
            formPath={Paths.Offers.StockReservationForm}
            listPath={Paths.Offers.StockReservationsList}
            id={id}
            headerText={{ resource: common.stockReservation, params: { id } }}
        >
            <FormRow>
                <FormTextField
                    name="name"
                    label={t(common.name)}
                />

                <FormDateTime
                    name="validFrom"
                    label={t(common.validFrom)}
                />

                <FormDateTime
                    name="validTo"
                    label={t(common.validTo)}
                />

                <FormSwitch
                    name="isActive"
                    label={t(common.isActive)}
                />
            </FormRow>

            <FormRow>
                <FormDivisionSelect
                    label={t(common.divisions)}
                    multiple
                    disableEmpty
                />

                <FormWarehouseSelect
                    name="warehouses"
                    label={t(common.warehouses)}
                    multiple
                    disableEmpty
                />

                <FormTimeSpan
                    name="reservationPeriod"
                    label={t(common.reservationPeriod)}
                />
            </FormRow>

            <FormRow>
                <Grid
                    item
                    xs={6}
                >
                    <ProductPredicate
                        name="includeProducts"
                        label={common.includeProducts}
                    />
                </Grid>

                <Grid
                    item
                    xs={6}
                >
                    <ProductPredicate
                        name="excludeProducts"
                        label={common.excludeProducts}
                    />
                </Grid>
            </FormRow>

            <FormList
                name="deliveries"
                label={common.deliveryConditions}
                defaultValue={{}}
                viewType="GridContainer"
                iconName="local_shipping"
            >
                {() => <DeliveryPredicate />}
            </FormList>
        </Form>
    );
};

export default StockReservationForm;
