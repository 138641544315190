import { commerce, content, crawler, identity, manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, createAction } from "utils/extensions";

const types = {
    campaigns: new AsyncTypes("AUTOCOMPLETE_CAMPAIGNS", commerce),
    brands: new AsyncTypes("AUTOCOMPLETE_BRANDS", content),
    brandNames: new AsyncTypes("AUTOCOMPLETE_BRAND_NAMES", content),
    vendors: new AsyncTypes("AUTOCOMPLETE_VENDORS", operations),
    userInfos: new AsyncTypes("AUTOCOMPLETE_USER_INFOS", identity),
    userInfosWithRole: new AsyncTypes("AUTOCOMPLETE_USER_INFOS_WITH_ROLE", identity),
    feedFormats: new AsyncTypes("AUTOCOMPLETE_FEED_FORMATS", commerce),
    contentCategories: new AsyncTypes("AUTOCOMPLETE_CONTENT_CATEGORIES", commerce),
    contentContainers: new AsyncTypes("AUTOCOMPLETE_CONTENT_CONTAINERS", commerce),
    shopConfigurations: new AsyncTypes("AUTOCOMPLETE_SHOP_CONFIGURATIONS", crawler),
    productsSuppliers: new AsyncTypes("AUTOCOMPLETE_PRODUCTS_SUPPLIERS", commerce),
    productsProducers: new AsyncTypes("AUTOCOMPLETE_PRODUCTS_PRODUCERS", commerce),
    productsBrands: new AsyncTypes("AUTOCOMPLETE_PRODUCTS_BRANDS", commerce),
    productsTags: new AsyncTypes("AUTOCOMPLETE_PRODUCTS_TAGS", commerce),
    documentCategories: new AsyncTypes("AUTOCOMPLETE_DOCUMENT_CATEGORIES", operations),
    documentTypes: new AsyncTypes("AUTOCOMPLETE_DOCUMENT_TYPES", operations),
    documentStatuses: new AsyncTypes("AUTOCOMPLETE_DOCUMENT_STATUSES", operations),
    productsCategories: new AsyncTypes("AUTOCOMPLETE_PRODUCTS_CATEGORIES", commerce),
    contactTypes: new AsyncTypes("AUTOCOMPLETE_CONTACT_TYPES", operations),
    cnCodes: new AsyncTypes("AUTOCOMPLETE_CN_CODES", operations),
    analyticGroups: new AsyncTypes("AUTOCOMPLETE_ANALYTIC_GROUPS", operations),
    vendorRecipients: new AsyncTypes("AUTOCOMPLETE_VENDOR_RECIPIENTS", operations),
    vendorUsers: new AsyncTypes("AUTOCOMPLETE_VENDOR_USERS", operations),
    vendorSubsidiaryGainUsers: new AsyncTypes("AUTOCOMPLETE_SUBSIDIARY_GAIN_USERS", operations),
    vendorSubsidiaryGainStatuses: new AsyncTypes("AUTOCOMPLETE_SUBSIDIARY_GAIN_STATUSES", operations),
    documentDefinitions: new AsyncTypes("AUTOCOMPLETE_DOCUMENT_DEFINITIONS", operations),
    countries: new AsyncTypes("AUTOCOMPLETE_COUNTRIES", commerce),
    activePromotionPeriods: new AsyncTypes("AUTOCOMPLETE_ACTIVE_PROMOTION_PERIODS", operations),
    promotionPeriods: new AsyncTypes("AUTOCOMPLETE_PROMOTION_PERIODS", operations),
    marketingTools: new AsyncTypes("AUTOCOMPLETE_MARKETING_TOOLS", operations),
    toolkitNames: new AsyncTypes("AUTOCOMPLETE_TOOLKIT_NAMES", operations),
    toolkits: new AsyncTypes("AUTOCOMPLETE_TOOLKITS", operations),
    membershipSeasons: new AsyncTypes("GET MEMBERSHIP_SEASONS", commerce),
    employeeDepartments: new AsyncTypes("AUTOCOMPLETE_EMPLOYEE_DEPARTMENTS", operations),
    employees: new AsyncTypes("AUTOCOMPLETE_EMPLOYEES", operations),
    procedureCategories: new AsyncTypes("AUTOCOMPLETE_PROCEDURE_CATEGORIES", operations),
    reservationGroups: new AsyncTypes("GET_RESERVATION_GROUPS", operations),
    setReservationGroups: createAction("SET_RESERVATION_GROUPS", manager),
};

export const productClassifications = ["War", "Top", "Normal", "Tail", "Seasonal"];
export const productClassificationsSelectItems = productClassifications.map((x) => ({
    value: x,
    name: x,
}));

export const pricePoints = ["Economy", "Mainstream", "Premium"];
export const pricePointsSelectItems = pricePoints.map((x) => ({
    value: x,
    name: x,
}));

export default types;
