import { StockReservationDocument } from "Commerce-Offer";
import { api } from "api";
import { useColumns } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { QueryTable } from "components-ts/tables";
import { View } from "components-ts/view";

const StockReservationsList: React.FC = () => {
    const columns = useColumns<StockReservationDocument>(() => [
        {
            property: "name",
            label: "name",
        },
        {
            property: "validFrom",
            label: "validFrom",
            as: "dateTime",
        },
        {
            property: "validTo",
            label: "validTo",
            as: "dateTime",
        },
        {
            property: "isActive",
            label: "isActive",
            as: "boolean",
        },
        {
            property: "createdAt",
            label: "createdAt",
            as: "dateTime",
        },
        {
            property: "updatedAt",
            label: "updatedAt",
            as: "dateTime",
        },
        {
            actionType: "preview",
            link: (item) =>
                Paths.GeneratePath(Paths.Offers.StockReservationsList, {
                    id: item.id,
                }),
        },
        {
            actionType: "delete",
        },
    ]);

    return (
        <View
            headerText="stockReservation"
            isMainView
        >
            <QueryTable
                queryHook={api.commerce.offers.stockReservations.useFindStockReservations}
                columns={columns}
                formPath={Paths.Offers.StockReservationForm}
                defaultParams={{ isActive: true }}
                removeHook={api.commerce.offers.stockReservations.useDeleteStockReservation}
                getItemRemoveName={(item) => item.name}
            />
        </View>
    );
};

export default StockReservationsList;
