import { StockReservationDocument } from "Commerce-Offer";
import { PagedResult, QueryTableParams } from "Shared";
import { useCreateOrUpdateMutation, useDeleteMutation, useGetPagedQuery, useGetQuery } from "api/hooks";

const url = (id?: string) => `api/v1/offer/products/stock-reservations${id ? `/${id}` : ""}`;

export const useFindStockReservations = (
    params: QueryTableParams = {
        pageIndex: 1,
        pageSize: 25,
    }
) => {
    return useGetPagedQuery<PagedResult<StockReservationDocument>, QueryTableParams>({
        app: "commerce",
        url: url(),
        queryParams: params,
    });
};

export const useGetStockReservation = (id?: string) => {
    return useGetQuery<StockReservationDocument>({
        app: "commerce",
        url: url(id),
        shouldMakeRequest: Boolean(id),
    });
};

export const useCreateOrUpdateStockReservation = (id?: string) => {
    return useCreateOrUpdateMutation<StockReservationDocument, StockReservationDocument>({
        app: "commerce",
        url: url(id),
        id: id,
    });
};

export const useDeleteStockReservation = (id?: string) => {
    return useDeleteMutation({
        app: "commerce",
        url: url(id),
    });
};
