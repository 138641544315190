import Layout from "components/Grid/Layout";
import TabLabel from "components/Tabs/TabLabel";
import Tabs from "components/Tabs/Tabs";
import roles, { SalesDepartmentRoles } from "consts/roles";
import { useUser } from "context/UserContext/UserContext";
import { actions } from "store/vendors/action";
import { useMappedDispatch, useParams, useRouterState } from "utils/hooks";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import {
    VendorCertificateRoles,
    VendorContactRoles,
    VendorContractRoles,
    VendorCorrectionRequestRoles,
    VendorCreditLimitRoles,
    VendorDataRoles,
    VendorDocumentAndTurnoverRoles,
    VendorMarketingBudgetRoles,
    VendorRefundationRoles,
    VendorSubsidiaryGainRoles,
} from "routing-ts/Routes/VendorsRoles";
import {
    Certificates,
    Contacts,
    Contracts,
    CorrectionRequests,
    CreditLimits,
    Decision,
    Documents,
    Invoices,
    MarketingBudgetList,
    OrdersList,
    Refundations,
    SubsidiaryGains,
    Turnovers,
    VendorData,
    VendorUsers,
} from "./components";

const VendorForm = ({ isSubbmitRequested, name }) => {
    const { id } = useParams();
    const { tabIndex } = useRouterState();
    const { t } = useTranslation("vendor");
    const { isInRole } = useUser();
    const dispatch = useDispatch();
    const isVendor = isInRole(roles.Vendor);
    const { updateVendor, addContractChangesDecision, addIsActiveChangeDecision, updateVendorUsers } = useMappedDispatch(actions);
    useEffect(() => {
        dispatch(actions.initVendorForm(id));
    }, [isVendor, id, dispatch]);

    //prettier-ignore
    const tabLabels = [
        new TabLabel(t(vendor.vendorData), [...VendorDataRoles, roles.Vendor]),
        new TabLabel(t(vendor.contactData), VendorContactRoles),
        new TabLabel(t(vendor.contracts), VendorContractRoles),
        new TabLabel(t(vendor.sg), VendorSubsidiaryGainRoles),
        new TabLabel(t(vendor.refundations), VendorRefundationRoles),
        new TabLabel(t(vendor.correctionRequests), VendorCorrectionRequestRoles),
        new TabLabel(t(vendor.creditLimits), VendorCreditLimitRoles),
        new TabLabel(t(vendor.marketingBudget), VendorMarketingBudgetRoles),
        new TabLabel(t(vendor.decisions), [roles.SalesManager]),
        new TabLabel(t(vendor.documents), VendorDocumentAndTurnoverRoles),
        new TabLabel(t(vendor.certificates), VendorCertificateRoles),
        new TabLabel(t(vendor.turnovers), VendorDocumentAndTurnoverRoles),
        new TabLabel(t(vendor.assignedUsers), [...SalesDepartmentRoles]),
        new TabLabel(t(vendor.invoices), []),
        new TabLabel(t(vendor.OrdersList), []),
    ].filter((_, i) => (id.includes("I") ? i === 1 : true));

    const handleSubmit = async (values, collectionUpdated) => {
        const { meta } = await updateVendor(values, id, collectionUpdated);
        if (meta.formSubmit === "SUCCESS") {
            dispatch(actions.initVendorForm(id));
        }
    };

    const handleDecision = async (changesAccepted, comment, contractId) => {
        const { meta } = await addContractChangesDecision(changesAccepted, comment, id, contractId);
        if (meta.formSubmit === "SUCCESS") {
            dispatch(actions.getChangedContracts(id));
        }
    };

    const handleIsActiveDecision = async (changeAccepted) => {
        const { meta } = await addIsActiveChangeDecision(changeAccepted, id);
        if (meta.formSubmit === "SUCCESS") {
            dispatch(actions.initVendorForm(id));
        }
    };

    const handleSubmitUsers = async (users) => {
        const { meta } = await updateVendorUsers(id, users);
        if (meta.formSubmit === "SUCCESS") {
            dispatch(actions.initVendorForm(id));
        }
    };

    return (
        <Layout
            main
            headerText={t(vendor.vendor, {
                id: id ? id : "",
                name: name ? name : "",
            })}
            navigationProps={{
                backProps: { path: "/vendors" },
            }}
        >
            <Tabs
                tableLabels={tabLabels}
                tabIndex={tabIndex}
            >
                <VendorData
                    isSubbmitRequested={isSubbmitRequested}
                    updateVendor={(values) => {
                        handleSubmit(values, "");
                    }}
                    handleIsActiveDecision={(changeAccepted) => {
                        handleIsActiveDecision(changeAccepted);
                    }}
                />
                <Contacts
                    isSubbmitRequested={isSubbmitRequested}
                    updateVendor={(values) => {
                        handleSubmit(values.contactPeople, "contact-people");
                    }}
                />
                <Contracts isSubbmitRequested={isSubbmitRequested} />
                <SubsidiaryGains />
                <Refundations />
                <CorrectionRequests />
                <CreditLimits
                    vendorId={id}
                    isSubbmitRequested={isSubbmitRequested}
                />
                <MarketingBudgetList />
                <Decision
                    isSubbmitRequested={isSubbmitRequested}
                    vendorId={id}
                    handleDecision={(changesAccepted, comment, contractId) => {
                        handleDecision(changesAccepted, comment, contractId);
                    }}
                />
                <Documents />
                <Certificates />
                <Turnovers />
                <VendorUsers
                    isSubbmitRequested={isSubbmitRequested}
                    onSubmitUsers={(users) => {
                        handleSubmitUsers(users);
                    }}
                />
                <Invoices vendorId={id} />
                <OrdersList />
            </Tabs>
        </Layout>
    );
};

const stateToProps = (state) => {
    const { vendorPms } = state;
    const { isSubbmitRequested, vendorForm, contracts } = vendorPms;

    return {
        isSubbmitRequested,
        initialValues: {
            ...vendorForm,
            contracts,
        },
        name: vendorForm?.name,
    };
};

export default connect(stateToProps)(VendorForm);
