import { forwardRef } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import { PatternFormat } from "react-number-format";
import { FormControlProps } from "./types";
import { TextFieldProps } from "control-types";
import { TextField } from "components-ts/controls";
import Controller from "./Controller";

type FormTimeSpanProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = Omit<
    FormControlProps<string, TFieldValues, TName>,
    "defaultValue" | "emptyValue"
> & {};

const TextFieldProxy = forwardRef<
    HTMLDivElement,
    Omit<TextFieldProps, "onChange"> & { onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void }
>(({ onChange, ...props }, ref) => {
    return (
        <TextField
            ref={ref}
            onChange={(_newValue, event) => {
                if (event) {
                    onChange(event);
                }
            }}
            {...props}
        />
    );
});

const FormTimeSpan = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    name,
    label,
    width,
    readOnly,
}: FormTimeSpanProps<TFieldValues, TName>): JSX.Element => {
    return (
        <Controller
            name={name}
            render={({ field: { ref, onChange, disabled: _, value }, fieldState: { error, isDirty, isTouched } }) => {
                return (
                    <PatternFormat
                        format="##.##:##:##"
                        patternChar="#"
                        mask={["D", "D", "H", "H", "m", "m", "s", "s"]}
                        // api returns without following zero, so when field is not touched and has value add 0 at start, when is missing
                        value={!isTouched && value ? (value.length < 11 ? `0${value}` : value) : value}
                        onValueChange={(values) => {
                            if (values.value === "") {
                                onChange(undefined);
                            } else {
                                onChange(values.formattedValue);
                            }
                        }}
                        allowEmptyFormatting
                        getInputRef={ref}
                        label={label}
                        disabled={readOnly}
                        customInput={TextFieldProxy}
                        error={{
                            hasError: Boolean(error),
                            message: error?.message,
                        }}
                    />
                );
            }}
            defaultValue={""}
            transform={(val) => (val ? val : "")}
            width={width}
        />
    );
};

export default FormTimeSpan;
