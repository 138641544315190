import {
    DeviceTypeForm,
    DeviceTypeList,
    DocumentCategoryForm,
    DocumentCategoryList,
    DocumentTypeForm,
    DocumentTypeList,
} from "views-ts/dictionaries";
import {
    CNStakesForm,
    CNStakesList,
    ContactTypeForm,
    ContactTypeList,
    DocumentDefinitionsForm,
    DocumentDefinitionsList,
    ProcedureCategoriesList,
    ProcedureCategoryForm,
} from "views/dictionaries";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";

export const Dictionaries: PrivateRoute[] = [
    new PrivateRoute(
        "/dictionaries",
        ["AXUser", "Management", "SalesManager", "Auditor", "HelpDesk", "AccountantManager", "TradeMarketingManager"],
        undefined,
        "dictionaries",
        undefined,
        [
            new PrivateRoute(Paths.Dictionaries.DocumentCategoriesList, ["Auditor"], DocumentCategoryList, "documentCategories", "local_library"),
            new PrivateRoute(Paths.Dictionaries.DocumentCategoryForm, ["Auditor"], DocumentCategoryForm),

            new PrivateRoute(Paths.Dictionaries.DocumentTypesList, ["Auditor"], DocumentTypeList, "documentTypes", "local_library"),
            new PrivateRoute(Paths.Dictionaries.DocumentTypeForm, ["Auditor"], DocumentTypeForm),

            new PrivateRoute(Paths.Dictionaries.ContactTypesList, ["Management", "SalesManager"], ContactTypeList, "contactTypes", "local_library"),
            new PrivateRoute(Paths.Dictionaries.ContactTypeForm, ["Management", "SalesManager"], ContactTypeForm),

            new PrivateRoute(
                Paths.Dictionaries.CnStakesList,
                ["Management", "AXUser", "SalesManager", "Auditor", "AccountantManager"],
                CNStakesList,
                "cnStakes",
                "local_library"
            ),
            new PrivateRoute(Paths.Dictionaries.CnStakeForm, ["Management", "SalesManager", "Auditor", "AccountantManager"], CNStakesForm),

            new PrivateRoute(
                Paths.Dictionaries.DocumentDefinitionsList,
                ["Auditor", "SalesManager"],
                DocumentDefinitionsList,
                "documentDefinitions",
                "local_library"
            ),
            new PrivateRoute(Paths.Dictionaries.DocumentDefinitionForm, ["Auditor", "SalesManager"], DocumentDefinitionsForm),
            new PrivateRoute(
                Paths.Dictionaries.ProcedureCategoriesList,
                ["Auditor"],
                ProcedureCategoriesList,
                "procedureCategories",
                "local_library"
            ),
            new PrivateRoute(Paths.Dictionaries.ProcedureCategoryForm, ["Auditor"], ProcedureCategoryForm),

            new PrivateRoute(Paths.Dictionaries.DeviceTypeList, ["HelpDesk"], DeviceTypeList, "deviceTypes", "devices_other"),
            new PrivateRoute(Paths.Dictionaries.DeviceTypeForm, ["HelpDesk"], DeviceTypeForm),
        ]
    ),
];
