import { StockReservationDocument } from "Commerce-Offer";
import moment from "moment";
import { notEmptyArray } from "utils-ts/validations";
import { DeliveryPredicateValidator } from "utils-ts/validations/commerce";
import { ProductPredicateValidator } from "utils-ts/validations/commerce/productPredicateValidator";
import { ExtendedValidator } from "utils-ts/validations/extendedValidator";
import {
    tValidation,
    validation,
} from "utils-ts/validations/translation";

export class StockReservationDocumentValidator extends ExtendedValidator<StockReservationDocument> {
    constructor() {
        super();

        this.ruleFor("divisions").must({
            predicate: (value, model) => {
                return (
                    (this.isValueEmpty(value) &&
                        !this.isValueEmpty(
                            model.warehouses
                        )) ||
                    (!this.isValueEmpty(value) &&
                        this.isValueEmpty(
                            model.warehouses
                        ))
                );
            },
            message: tValidation(
                validation.mustBeEmpty,
                {
                    when: "jeśli uzupełniono pole Magazyny",
                }
            ),
        });

        this.ruleFor("warehouses").must({
            predicate: (value, model) => {
                return (
                    (this.isValueEmpty(value) &&
                        !this.isValueEmpty(
                            model.divisions
                        )) ||
                    (!this.isValueEmpty(value) &&
                        this.isValueEmpty(
                            model.divisions
                        ))
                );
            },
            message: tValidation(
                validation.mustBeEmpty,
                {
                    when: "jeśli uzupełniono pole Oddziały",
                }
            ),
        });

        this.ruleFor("validFrom")
            .lessThan("validTo")
            .whenNotEmpty([
                "validFrom",
                "validTo",
            ]);

        this.ruleFor("validTo")
            .greaterThan("validFrom")
            .whenNotEmpty([
                "validFrom",
                "validTo",
            ]);

        this.ruleFor("name")
            .notEmpty()
            .length(3, 250);

        this.ruleFor("includeProducts")
            .notNull()
            .setValidator(
                new ProductPredicateValidator(
                    true
                )
            );

        this.ruleFor("excludeProducts")
            .setValidator(
                new ProductPredicateValidator(
                    false
                )
            )
            .whenNotEmpty();

        this.ruleFor("deliveries").must(
            notEmptyArray(1)
        );

        this.ruleForEach(
            "deliveries"
        ).setValidator(
            new DeliveryPredicateValidator(true)
        );

        this.ruleFor("reservationPeriod")
            .notEmpty()
            .must({
                predicate: (value) => {
                    const duration =
                        moment.duration(value);

                    const months =
                        duration.months();
                    const days =
                        duration.days() +
                        months * 31;
                    const formatted = `${
                        days < 10
                            ? `0${days}`
                            : days
                    }.${duration.hours()}:${duration.minutes()}:${duration.seconds()}`;

                    return value === formatted;
                },
                message: tValidation(
                    validation.formatRegex
                ),
            })
            .must({
                predicate: (value) => {
                    const duration =
                        moment.duration(value);

                    return duration.asDays() >= 1;
                },
                message: tValidation(
                    validation.greaterThan,
                    {
                        number: "01.00:00:00",
                    }
                ),
            });
    }
}
