import { SimplifiedProduct } from "Commerce-Offer";
import { useEffect, useState } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import { api } from "api";
import { FormControlProps } from "./types";
import { ListInput } from "components-ts/controls";
import ProductChip from "components-ts/visualizations/ProductChip";
import Controller from "./Controller";

const FormProductIdsList = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    name,
    label,
    defaultValue,
    emptyValue,
    width,
    readOnly,
}: FormControlProps<string, TFieldValues, TName>): JSX.Element => {
    const { data, status, queryParams, setQueryParams } = api.commerce.offers.products.useGetSimplifiedProduct();
    const [products, setProducts] = useState<Record<number, SimplifiedProduct>>({});
    const [productsIds, setProductsIds] = useState<string[]>([]);
    useEffect(() => {
        if (status === "success") {
            const temp = {
                ...products,
            };

            (data || []).forEach((p) => {
                temp[Number(p.productId)] = p;
            });

            setProducts(temp);
            setTimeout(() => {
                setQueryParams({ productIds: (productsIds || []).slice(250, 500).filter((p) => products[Number(p)] === undefined) });
                setProductsIds((productsIds || []).slice(250).filter((p) => products[Number(p)] === undefined));
            }, 500);
        }
    }, [status, (productsIds || []).length]);

    useEffect(() => {
        if (status === "pending" && (queryParams.productIds || []).length === 0 && (productsIds || []).length > 0) {
            setQueryParams({ productIds: (productsIds || []).slice(0, 250).filter((p) => products[Number(p)] === undefined) });
        }
    }, [productsIds]);

    return (
        <Controller
            name={name}
            render={({ field: { ref, onChange, value, disabled, onBlur }, fieldState: { error } }) => {
                if (
                    Array.isArray(value) &&
                    value.length > 0 &&
                    value.some((v: string) => products[Number(v)] === undefined && !productsIds.includes(v))
                ) {
                    setProductsIds(value.filter((v: string) => products[Number(v)] === undefined && !productsIds.includes(v)));
                }

                return (
                    <ListInput
                        ref={ref}
                        label={label}
                        value={value ?? []}
                        onChange={(newValue: string[]) => {
                            onChange(newValue);
                        }}
                        renderItem={({ item, handleRemove }) => {
                            const product = products[Number(item)];

                            return (
                                <ProductChip
                                    value={item}
                                    handleRemove={handleRemove}
                                    product={product}
                                    readOnly={readOnly}
                                />
                            );
                        }}
                        readOnly={readOnly}
                        error={{
                            hasError: error !== undefined,
                            message: error?.message,
                        }}
                    />
                );
            }}
            defaultValue={defaultValue}
            transform={(val) => (val ? val : emptyValue)}
            width={width}
        />
    );
};

export default FormProductIdsList;
